import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  body1: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "white",
  },
});

const EMPAnnouncementBanner = (props) => {
  const { text, textAlign } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box p={1} textAlign={textAlign} sx={{ bgcolor: "#0068d0" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={9}>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EMPAnnouncementBanner;
