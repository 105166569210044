import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MotionCloseIcon from "../../Icons/MotionCloseIcon.svg";
import DialogActions from "@material-ui/core/DialogActions";
import EMPButton from "../EMPButton/EMPButton";

// Available props:
// dialogTitle: string
// titleType: string (default, warning, success)
// hasCloseIcon: Boolean
// handleClose: Func
// onEnter: Func
// onExit: Func
// hasActionButton: Boolean
// actionButtonText: String
// onConfirm: Func
// open: Boolean

const useStyles = makeStyles(
  {
    root: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      color: "#000000",
      fontSize: 16,
    },
    paper: {
      borderRadius: "16px",
    },
    dialogTitle: {
      padding: 16,
    },
    title: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      color: "#00142E",
      fontSize: 24,
      lineHeight: "32px",
    },
    titleWarning: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      color: "#BF152C",
      fontSize: 24,
      lineHeight: "32px",
    },
    titleSuccess: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      color: "#008200",
      fontSize: 24,
      lineHeight: "32px",
    },
    dialogContent: {
      padding: "0px 16px 24px 16px",
    },
    actions: {
      padding: "16px",

      "&.MuiDialogActions-spacing > :last-child": {
        marginLeft: 16,
      },
    },
    edgeEnd: {
      padding: 0,

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    name: "EMPDialog",
  }
);

const EMPDialog = (props) => {
  const {
    open,
    dialogTitle,
    titleType,
    hasCloseIcon,
    handleClose,
    onEnter,
    onExit,
    children,
    hasSecondaryActionButton,
    secondaryActionButtonText,
    hasActionButton,
    actionButtonText,
    onConfirm,
    disableActionButton,
    actionLoading,
    onSecondaryAction,
    dialogActionsDataTestId,
  } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) =>
        reason === "backdropClick" && hasCloseIcon && handleClose()
      }
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        onEnter,
        onExit,
      }}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={9}>
            <Typography
              variant="h4"
              classes={
                titleType === "warning"
                  ? { h4: classes.titleWarning }
                  : titleType === "success"
                  ? { h4: classes.titleSuccess }
                  : { h4: classes.title }
              }
              data-testid="dialog-title"
            >
              {dialogTitle}
            </Typography>
          </Grid>
          {hasCloseIcon && (
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                disableRipple
                classes={{ root: classes.edgeEnd }}
                data-testid="dialog-close-button"
              >
                <img src={MotionCloseIcon} alt="close" width="24px" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {children}
      </DialogContent>
      {(hasActionButton || hasSecondaryActionButton) && (
        <DialogActions
          classes={{ root: classes.actions }}
          data-testid={dialogActionsDataTestId}
        >
          {actionLoading && <CircularProgress size={24} />}
          {hasSecondaryActionButton && (
            <EMPButton
              buttonText={secondaryActionButtonText}
              buttonType="outlined"
              testId="dialog-secondary-action-button"
              onClick={onSecondaryAction}
            ></EMPButton>
          )}
          {hasActionButton && (
            <EMPButton
              buttonText={actionButtonText}
              buttonType="contained"
              testId="dialog-action-button"
              onClick={onConfirm}
              disabled={disableActionButton}
            ></EMPButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EMPDialog;
