import React from "react";
import UpperCaseText from "../../../utils/UpperCaseText";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { Grid, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

export const DashboardActions = (props) => {
  const { title, hideActionButton } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      {!hideActionButton && (
        <Grid item xs={12}>
          <Box textAlign="center">
            <EMPButton
              buttonType="outlined"
              buttonElement={Link}
              link={{
                pathname: `/customer-handling/${props.action}`,
                search: `?${props.actionTag}`,
                state: { actionRedirectFromDashboard: true },
              }}
              buttonText={props.t("DealerDashboard.viewActions")}
            ></EMPButton>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box textAlign="center">
          <EMPButton
            buttonElement={Link}
            link={{
              pathname: `/customer-handling/${props.location}`,
              search: `?${props.viewAllTag}`,
            }}
            buttonType="text"
            buttonText={
              UpperCaseText(title) === "ORDERS"
                ? props.t("DealerDashboard.viewSubmitted")
                : props.t("DealerDashboard.viewAll")
            }
          ></EMPButton>
        </Box>
      </Grid>
    </Grid>
  );
};
