import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import fordStyles from "./DashboardCards-Ford.module.scss";
import lincolnStyles from "./DashboardCards-Lincoln.module.scss";
import { SubTitle } from "./DashboardSubtitle";
import { Content } from "./DashboardContent";
import { DashboardActions } from "./DashboardActions";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import HasPermissionTo from "../../../common/HasPermissionTo";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Box,
} from "@material-ui/core";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { Link } from "react-router-dom";

let isFordBrand = true;
let styles = fordStyles;

const dashboardStyles = () => ({
  subTitle: {
    color: "#102b4e",
    textTransform: "uppercase",
  },
  divider: {
    width: "90%",
    margin: "0 auto",
  },
});

class MainDashboard extends Component {
  constructor(props) {
    super(props);
    this.dateRange = 7;
  }

  render() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    styles = isFordBrand ? fordStyles : lincolnStyles;
    return (
      <div className={styles.pagePadding}>
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          justifyContent="center"
        >
          <HasPermissionTo
            perform={["modelEDashboardCard"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <Grid item xs={12} sm={6} md={6}>
                <DashboardCard
                  {...this.props}
                  type="modele"
                  title={this.props.t("DealerDashboard.modele")}
                  data={this.props.dashboardNotifications.modelEData}
                  testid="modele-card"
                  pathname="model_e_orders"
                  location="model_e_orders"
                  viewAllTag="intcmp=emphp-cta-modelecard-viewall"
                  hideActionButton
                />
              </Grid>
            )}
          />
          <HasPermissionTo
            perform={["accessoriesDashboardCard"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={!this.props.accesoriesPricingNotSet}
            render={() => (
              <Grid item xs={12} sm={6} md={6}>
                <DashboardCard
                  {...this.props}
                  type="accessories"
                  title={this.props.t("DealerDashboard.accessories")}
                  showBreakDown
                  data={this.props.dashboardNotifications.accessoriesData}
                  testid="accessories-card"
                  viewAllTag="intcmp=emphp-cta-accessoriescard-view"
                  pathname="pricing/accessories"
                />
              </Grid>
            )}
          />
        </Grid>
      </div>
    );
  }
}

const DashboardCard = (props) => {
  const { type, pathname, viewAllTag } = props;

  return (
    <Card data-testid={props.testid} className={styles.card} variant="outlined">
      <CardHeader
        title={props.title}
        className={styles.title}
        disableTypography={true}
      />
      <SubTitle {...props} />
      <CardContent>
        <Content {...props} />
      </CardContent>
      <CardActions>
        {type === "accessories" ? (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box textAlign="center">
                <EMPButton
                  buttonText={props.t("DealerDashboard.viewAccessories")}
                  buttonElement={Link}
                  link={{
                    pathname: pathname,
                    search: `?${viewAllTag}`,
                    state: { redirectToAccSearch: true },
                  }}
                ></EMPButton>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <DashboardActions {...props} />
        )}
      </CardActions>
    </Card>
  );
};

export default withStyles(dashboardStyles)(MainDashboard);
