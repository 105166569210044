import React from "react";
import EMPGroupedHeading from "../../../shared/components/EMPGroupedHeading/EMPGroupedHeading";
import UpperCaseText from "../../../utils/UpperCaseText";
import { PIPELINE } from "../../../common/Constants";

// scenarios
// is Canadian field user who cannot see PII
// is cancelled reservation in Canada who cannot see PII - hasLabel and labelText is Cancelled

const DetailsHeader = (props) => {
  const { customerReservation, customerInfo, user } = props;

  // get details from user object
  const { market, userType } = user;
  const isCANUser = market === "CAN";
  const isFieldUser = userType && userType === "field_view";
  const isCANFieldViewUser = isFieldUser && isCANUser;

  // get details from customerReservation
  const { orderType, businessStatus, spotBuy, journeyType, subJourneyType } =
    customerReservation;
  const isReservation = orderType.toLowerCase() === "reservation";
  const isCancelled = businessStatus.toLowerCase() === "cancelled";
  const isModelE = journeyType === "Model-E" || journeyType === "Model-e";
  const isSpotBuy = spotBuy;
  const isFromPlant = UpperCaseText(subJourneyType) === PIPELINE;

  // business logic
  const cancelledReservationInCanada =
    isReservation && isCancelled && isCANUser;
  const modelESpotBuy = isModelE && isSpotBuy;
  const modelEFromPlant = isModelE && isFromPlant;

  const formatCustomerName = (firstName, lastName) => {
    if (firstName && lastName) {
      const first = firstName.charAt(0) + firstName.slice(1).toLowerCase();
      const last = lastName.charAt(0) + lastName.slice(1).toLowerCase();

      return `${first} ${last}`;
    } else {
      return "--";
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const dynamicCustomerInfo = () => {
    if (customerInfo !== null) {
      return !isCANFieldViewUser
        ? [
            formatCustomerName(customerInfo.firstName, customerInfo.lastName),
            formatPhoneNumber(customerInfo.phoneNumber),
            customerInfo.email.toLowerCase(),
          ]
        : [formatCustomerName(customerInfo.firstName, customerInfo.lastName)];
    } else {
      return [];
    }
  };

  const displayPurchaseType = (customerReservation) => {
    const { orderType } = customerReservation;

    const types = {
      reservation: "Reservation#",
      order: "Order#",
      purchase: "Purchase#",
    };

    if (customerReservation.purchaseInfo) {
      return types["purchase"];
    } else {
      return types[orderType.toLowerCase()];
    }
  };

  const displayLabel = () => {
    const labelMap = {
      inStore: {
        text: "In store",
        color: "primary",
      },
      fromPlant: {
        text: "From plant",
        color: "primary",
      },
    };

    if (modelESpotBuy) {
      return labelMap["inStore"];
    } else if (modelEFromPlant) {
      return labelMap["fromPlant"];
    } else {
      return undefined;
    }
  };

  return (
    <EMPGroupedHeading
      brand={props.user.brand}
      headingText={`${displayPurchaseType(customerReservation)} ${
        customerReservation.id
      }`}
      label={displayLabel()}
      subItems={dynamicCustomerInfo()}
      hideSubItems={cancelledReservationInCanada}
    ></EMPGroupedHeading>
  );
};

export default DetailsHeader;
