import fordStyles from "./OrderDetailNotifications.module.scss";
import lincolnStyles from "./OrderDetailNotificationsLincoln.module.scss";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HasPermissionTo from "../../../common/HasPermissionTo";
import React, { useState } from "react";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import { ReactComponent as LincolnInfoIcon } from "../../../imgs/lincoln-tooltip-icon.svg";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Grid, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import EVTaxCreditLinksDialog from "../modelEDetails/EVTaxCreditLinksDialog";

export const OrderDetailNotifications = (props) => {
  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

  const shortenInfo = (action) =>
    action === "INFORMATION ONLY" ? "INFO ONLY" : action;

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Grid
        data-testid="viewDetailsNotification"
        container
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
        className={
          props.isAccessoriesNotification
            ? `${styles.actionNotificationBox} ${styles.warningBoxColorYellow}`
            : props.warningColor
            ? `${styles.actionNotificationBox} ${styles.warningBoxColor}`
            : `${styles.actionNotificationBox} ${styles.whiteBoxColor}`
        }
      >
        <Grid item xs={12} sm={props.notificationGroup ? 8 : "auto"}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item style={{ fontSize: 0 }}>
              <Box mr={1}>
                {props.warningColor ? (
                  <ReportProblemOutlinedIcon />
                ) : IS_FORD_BRAND(props.user.brand) ? (
                  <InfoOutlinedIcon />
                ) : (
                  <LincolnInfoIcon />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box mr={2}>
                <p>{shortenInfo(props.showActionRequired)}</p>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <p>{props.showMessage}</p>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={props.notificationGroup ? 4 : 1}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs="auto">
            {!props.isAccessoriesNotification && (
              <p>{props.notificationGroup}</p>
            )}
          </Grid>

          <HasPermissionTo
            perform={["USOrderDetailsNotifications:write"]}
            permissions={props.user.permissions.rolePermissions}
            condition={props.showCloseIcon}
            render={() => {
              return (
                <Grid item xs="auto" style={{ fontSize: 0, cursor: "pointer" }}>
                  <Box ml={1}>
                    <CloseIcon
                      data-testid="closeNotification"
                      onClick={() => props.handleClose()}
                    />
                  </Box>
                </Grid>
              );
            }}
          />

          {props.showMoreInfoLink && (
            <Grid item xs="auto" style={{ cursor: "pointer" }}>
              <Box ml={1}>
                <EMPButton
                  buttonText="More Info"
                  buttonType="link"
                  testId="more-info-link"
                  onClick={openDialog}
                ></EMPButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <EVTaxCreditLinksDialog
        open={dialogOpen}
        hasCloseIcon={true}
        handleClose={handleClose}
        t={props.t}
      />
    </>
  );
};
