import {
  PREPROD_ANALYTICS_URL,
  PROD_ANALYTICS_URL,
} from "../../../common/Constants";
import { ENV } from "../../../utils/EmpUtil";
import {
  modelEPageLoadDetailDataLayer,
  onClickDataLayer,
  onClickDetailDataLayer,
  pageLoadDataLayer,
  pageLoadDetailDataLayer,
} from "./DataLayer";

let analyticsLoaded;
let deviceType;

const checkDeviceType = () => {
  let radUiString = "";
  const deviceOptions = {
    pc: "ui:rad:pc",
    tablet: "ui:rad:tablet",
    mobile: "ui:rad:mobile",
  };

  if (navigator.userAgent.match(/iPhone/i)) {
    radUiString = deviceOptions["mobile"];
  } else if (navigator.userAgent.match(/iPad/i)) {
    radUiString = deviceOptions["tablet"];
  } else {
    radUiString = deviceOptions["pc"];
  }

  deviceType = radUiString;
};

const generateTimestamp = (dateStr) => {
  return new Date(dateStr).toISOString();
};

const initAnalytics = () => {
  if (!window.__satelliteLoaded) {
    const script = document.createElement("script");
    script.src = ENV !== "prod" ? PREPROD_ANALYTICS_URL : PROD_ANALYTICS_URL;
    script.async = true;
    document.head.appendChild(script);
    checkDeviceType();
  }
};

const trackPageLoadEvent = (pageName, user) => {
  const { dealerId } = user;
  const timeStampPL = generateTimestamp(new Date());
  window.digitaldata = pageLoadDataLayer(
    pageName,
    dealerId,
    deviceType,
    timeStampPL
  );
  let num = 0;

  const eventInterval = setInterval(() => {
    num += 1;
    if (window.__satelliteLoaded) {
      analyticsLoaded = window.__satelliteLoaded;
      checkDeviceType();
      // page components are responsible for passing the correct payload
      window.digitaldata = pageLoadDataLayer(
        pageName,
        dealerId,
        deviceType,
        timeStampPL
      );
      if (analyticsLoaded) {
        window._satellite.track("empLoad");
      }
      clearInterval(eventInterval);
    }

    if (num === 10) {
      clearInterval(eventInterval);
    }
  }, 1000);
};

const trackPageLoadDetailEvent = (pageName, tool, orderId, user) => {
  const { dealerId } = user;
  const timeStampPL = generateTimestamp(new Date());

  window.digitaldata = pageLoadDetailDataLayer(
    pageName,
    tool,
    orderId,
    dealerId,
    deviceType,
    timeStampPL
  );
  let num = 0;

  const eventInterval = setInterval(() => {
    num += 1;
    if (window.__satelliteLoaded) {
      analyticsLoaded = window.__satelliteLoaded;
      checkDeviceType();
      // page components are responsible for passing the correct payload
      window.digitaldata = pageLoadDetailDataLayer(
        pageName,
        tool,
        orderId,
        dealerId,
        deviceType,
        timeStampPL
      );
      if (analyticsLoaded) {
        window._satellite.track("empLoadDetail");
      }
      clearInterval(eventInterval);
    }
    if (num === 10) {
      clearInterval(eventInterval);
    }
  }, 100);
};

const trackModelEPageLoadDetailEvent = (
  pageName,
  orderId,
  deliveryStatus,
  user
) => {
  const { dealerId } = user;
  const timeStampPL = generateTimestamp(new Date());

  // page components are responsible for passing the correct payload
  window.digitaldata = modelEPageLoadDetailDataLayer(
    pageName,
    orderId,
    deliveryStatus,
    dealerId,
    deviceType,
    timeStampPL
  );

  if (window.__satelliteLoaded) {
    window._satellite.track("empLoadDetail");
  }
};

const trackOnClickEvent = (pageName, onclick, onclickLinkName, user) => {
  const { dealerId } = user;
  const timeStampCTA = generateTimestamp(new Date());

  window.digitaldata = onClickDataLayer(
    pageName,
    onclick,
    onclickLinkName,
    dealerId,
    deviceType,
    timeStampCTA
  );

  if (window.__satelliteLoaded) {
    window._satellite.track("empOnclick");
  }
};

const trackOnClickDetailEvent = (
  pageName,
  tool,
  toolDescriptor,
  orderId,
  onclick,
  onclickLinkName,
  user
) => {
  const { dealerId } = user;
  const timeStampCTA = generateTimestamp(new Date());

  window.digitaldata = onClickDetailDataLayer(
    pageName,
    tool,
    toolDescriptor,
    orderId,
    onclick,
    onclickLinkName,
    dealerId,
    deviceType,
    timeStampCTA
  );

  if (window.__satelliteLoaded) {
    window._satellite.track("empOnclickDetail");
  }
};

const trackOnDownloadEvent = (pageName, onclick, onclickLinkName, user) => {
  const { dealerId } = user;
  const timeStampCTA = generateTimestamp(new Date());

  window.digitaldata = onClickDataLayer(
    pageName,
    onclick,
    onclickLinkName,
    dealerId,
    deviceType,
    timeStampCTA
  );

  if (window.__satelliteLoaded) {
    window._satellite.track("empDownload");
  }
};

export {
  initAnalytics,
  trackPageLoadEvent,
  trackPageLoadDetailEvent,
  trackModelEPageLoadDetailEvent,
  trackOnClickEvent,
  trackOnClickDetailEvent,
  trackOnDownloadEvent,
};
