import React from "react";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import UpperCaseText from "../../../utils/UpperCaseText";
import fordStyles from "./DashboardCards-Ford.module.scss";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import lincolnStyles from "./DashboardCards-Lincoln.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

let isFordBrand = true;
let styles = fordStyles;

export const Content = (props) => {
  const { showBreakDown, data, title, type } = props;
  isFordBrand = IS_FORD_BRAND(props.user.brand);
  styles = isFordBrand ? fordStyles : lincolnStyles;

  const generateTitle = (title) => {
    const uppercaseTitle = UpperCaseText(title);
    if (uppercaseTitle === "RESERVATIONS")
      return `${props.t("DealerDashboard.addedToday")}`;
    else if (uppercaseTitle === "ORDERS")
      return `${title} ${props.t("DealerDashboard.requireAcceptReject")}`;
    else if (uppercaseTitle === "PURCHASES")
      return `${title} ${props.t("DealerDashboard.requireAction")}`;
    else if (uppercaseTitle === "ACCESSORIES")
      return props.t("DealerDashboard.newAccessories");
    else if (uppercaseTitle === "MODEL E")
      return props.t("DealerDashboard.deliveriesScheduled");
  };

  return (
    <Grid
      container
      className={
        (data && data.requireAttention === 0) ||
        (data && data.total === 0 && type === "accessories") ||
        UpperCaseText(title) === "RESERVATIONS" ||
        UpperCaseText(title) === "MODEL E"
          ? styles.contentGreen
          : styles.contentOrange
      }
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item sm={UpperCaseText(title) === "ORDERS" ? 8 : 6}>
        <div className={styles.cardNumber}>
          {data ? (
            <>
              <p className={styles.bigTotal}>
                {type === "accessories"
                  ? data && data.total
                  : data && data.requireAttention}
              </p>
              <p className={styles.bigDescription}>{generateTitle(title)}</p>
            </>
          ) : (
            <Skeleton height={120} />
          )}
        </div>
      </Grid>
      {showBreakDown && <BreakDown {...props} />}
    </Grid>
  );
};

const BreakDownItem = (props) => {
  const { count, singularName, pluralName, showDivider, classes } = props;

  return (
    <>
      <Grid item xs={12} className={styles.breakdownItem}>
        <span className={styles.breakdownNumber}>{count}</span>{" "}
        {count === 1 ? singularName : pluralName}
      </Grid>

      {showDivider && (
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
      )}
    </>
  );
};
const BreakDown = (props) => {
  const { classes, data, type } = props;

  return (
    <Grid item xs={6}>
      <Grid container direction="column" className={styles.breakdown}>
        {type !== "accessories" && (
          <>
            <BreakDownItem
              count={data && data.purchaseRequests && data.purchaseRequests}
              singularName={props.t("DealerDashboard.purchaseRequest")}
              pluralName={props.t("DealerDashboard.purchaseRequests")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.tradeIns && data.tradeIns}
              singularName={props.t("DealerDashboard.tradeIn")}
              pluralName={props.t("DealerDashboard.tradeIns")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.downPayments && data.downPayments}
              singularName={props.t("DealerDashboard.downPayment")}
              pluralName={props.t("DealerDashboard.downPayments")}
              classes={classes}
              showDivider={false}
            />
          </>
        )}

        {type === "accessories" && (
          <>
            <BreakDownItem
              count={data && data.bedProducts && data.bedProducts}
              singularName={props.t("DealerDashboard.bedProduct")}
              pluralName={props.t("DealerDashboard.bedProducts")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.electronics && data.electronics}
              singularName={props.t("DealerDashboard.electronic")}
              pluralName={props.t("DealerDashboard.electronics")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.exterior && data.exterior}
              singularName={props.t("DealerDashboard.exterior")}
              pluralName={props.t("DealerDashboard.exterior")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.interior && data.interior}
              singularName={props.t("DealerDashboard.interior")}
              pluralName={props.t("DealerDashboard.interior")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.wheels && data.wheels}
              singularName={props.t("DealerDashboard.wheel")}
              pluralName={props.t("DealerDashboard.wheels")}
              classes={classes}
              showDivider={true}
            />

            <BreakDownItem
              count={data && data.misc && data.misc}
              singularName={props.t("DealerDashboard.miscellaneous")}
              pluralName={props.t("DealerDashboard.miscellaneous")}
              classes={classes}
              showDivider={false}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
