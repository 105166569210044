import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { OrderDetailNotifications } from "./OrderDetailNotifications";
import UpperCaseText from "../../../utils/UpperCaseText";

class NADealerNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  displayActionType = (type) => {
    return this.isNotificationType(type)
      ? this.props.t("CustomerOrderDetails.informationOnly")
      : this.props.t("CustomerOrderDetails.actionRequired");
  };

  getNotificationGroup = (notificationMsg) => {
    switch (UpperCaseText(notificationMsg)) {
      case "RESERVATION PENDING CONSUMER CONSENT TO CONVERT TO ORDER":
        return "Customer Action Needed";

      case "PROCESS CONSUMER DEPOSIT":
      case "ORDER NEEDS APPROVAL":
      case "PURCHASE REQUEST NEEDS APPROVAL":
      case "PROCESS CUSTOMER DEPOSIT":
      case "VEHICLE IS CURRENTLY BEING HELD WITH A DEPOSIT":
      case "ESIGN REQUESTED BY CUSTOMER":
      case "VEHICLE IS CURRENTLY BEING HELD":
        return "Action Requested";

      case "VEHICLE REPORTED SOLD BY DEALER":
      case "RESERVATION CONSUMER CONSENT TO CONVERT TO ORDER HAS BEEN APPROVED BY CUSTOMER":
      case "FINANCE PENDING - CONTACT THE CUSTOMER":
      case "ONLINE APPLICATION APPROVED":
      case "FINANCE DECLINED - CONTACT THE CUSTOMER":
      case "ESIGN CONTRACT DISTRIBUTED":
        return "Status Updated";

      case "TRADE-IN EVALUATION NEEDS PROCESSING":
        return "Trade Deadline";

      default:
        return "";
    }
  };

  isNotificationType = (type) => {
    return type === "NOTIFICATION";
  };

  render() {
    let orders = [];
    if (this.props.notifications.length > 0) {
      orders = this.props.notifications;
    }
    return (
      <div>
        {orders.map((notification, index) => (
          <OrderDetailNotifications
            key={index}
            index={index}
            user={this.props.user}
            showActionRequired={this.displayActionType(
              notification && notification.notificationType
            )}
            showMessage={notification.params.NotificationMsg}
            notificationGroup={this.getNotificationGroup(
              notification.params.NotificationMsg
            )}
            warningColor={
              !this.isNotificationType(notification.notificationType)
            }
            showCloseIcon={
              this.isNotificationType(notification.notificationType) &&
              !notification.custom
            }
            t={this.props.t}
            showMoreInfoLink={notification.showMoreInfoLink}
            handleClose={() => this.props.deleteNotification(notification.uid)}
            isAccessoriesNotification={this.props.isAccessoriesNotification}
          />
        ))}
      </div>
    );
  }
}

export default withTranslation("emp")(NADealerNotifications);
